<template>
  <v-card color="background">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      stateless
      fixed
      expand-on-hover
      color="background"
    >
      <v-list
        dense
        class="pa-0"
      >
        <v-list-item
          class="px-2"
          data-v-step="1"
          @click="openExternalLink('https://www.deltares.nl')"
        >
          <v-list-item-avatar>
            <custom-icon name="deltares" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title color="primary">
              Deltares
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item
          class="px-2"
          data-v-step="1"
          @click="openExternalLink('https://www.hkv.nl')"
        >
          <v-list-item-avatar>
            <custom-icon name="hkv" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title color="primary">
              HKV
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="px-2"
          data-v-step="1"
          @click="openExternalLink('https://www.haedes.eu')"
        >
          <v-list-item-avatar>
            <div class="icon">
              <img src="/haedes.png">
            </div>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title color="primary">
              Haedes
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template #append>
        <div>
          <v-list
            dense
            class="pa-0"
          >
            <v-list-item @click="$emit('toggle-tour')">
              <v-list-item-icon class="mr-6">
                <v-icon>mdi-flag-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Tour</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item-group active-class="active-theme">
              <v-list-item @click="$emit('toggle-about')">
                <v-list-item-icon class="mr-6">
                  <custom-icon name="info" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>About</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="$emit('toggle-account')">
                <v-list-item-icon class="mr-6">
                  <custom-icon name="account" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    Account
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import CustomIcon from '@/components/CustomIcon'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    CustomIcon
  },
  computed: {
    ...mapGetters(['getThemes', 'getActiveTheme']),
    dataRoute() {
      const { path } = this.$route
      return !!path.includes('data')
    }
  },
  data() {
    return {
      drawer: true,
      mini: true,
      activeTheme: null
    }
  },
  methods: {
    ...mapMutations(['toggleActiveTheme']),
    isActive(id) {
      return this.activeTheme === id
    },
    toggleTheme(id) {
      this.toggleActiveTheme(id)

      if (this.activeTheme === id) {
        this.activeTheme = null
      } else {
        this.activeTheme = id
      }

      this.$emit('change-theme')
    },
    openExternalLink(link) {
      window.open(link, '_blank')
    }
  }
}
</script>

<style>
.active-theme {
  color: var(--v-blue100-base) !important;
}
</style>
